import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 10 sets of 10\\@55% 1RM with 1:00 rest between sets.`}</p>
    <p>{`Score = `}{`#`}{` of completed unbroken sets.`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`20-Pullups`}</p>
    <p>{`10-Devil’s Press (50’s/35’s)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      